import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import './style.css';
import secureimg from '../../assets/img/secure-icon.svg';
import axios from 'axios';
import validator from 'validator';
import config from '../../../utils/config';
import { FormattedMessage } from 'react-intl';

function ForgotForm() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disabledEmailBtn, setDisabledEmailBtn] = useState(false);

    const validateEmail = (e) => {
        var email = e.target.value;
        if (!validator.isEmail(email)) {
            setError('Enter valid Email!');
            setDisabledEmailBtn(false);
            setSuccess('');
        } else {
            setDisabledEmailBtn(true);
            setError('');
            setSuccess('');
        }
    };
    const handleLogin = () => {
        // props.history.push('/contracts')
        setError(null);
        setLoading(true);

        axios
            .post(`${config.baseUrl}/customerportal/signup`, {
                email: email,
            })
            .then((response) => {
                setLoading(false);
                setSuccess(response.data.message);
                setEmail('');
            })
            .catch((error) => {
                setLoading(false);
                console.log('error', error);
                setError(error.response.data.message);
            });
    };
    return (
        <div className="form brd-rds-4 bg-white padding-20 mx-wdt-740px w-100 text-center">
            <div className="mx-wdt-420px m-auto">
                <h3 className="font-weight-bold mg-b-10">
                    <FormattedMessage id="signup" defaultMessage="Sign up" />
                </h3>
                <div className="img mg-b-10">
                    <img src={secureimg} alt="icon" />
                </div>
                <div className="text mg-b-26">
                    <FormattedMessage
                        id="signup.description"
                        defaultMessage="Enter your e-mail address and we'll send you a password on email. We encourage you to change the default password."
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="Email">Email</label>
                    <input
                        type="email"
                        id="Email"
                        required
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            validateEmail(e);
                        }}
                    />
                </div>
                <div className="d-flex justify-content-between flex-wrap">
                    <Link to="/" className="btn btn-white-border-cust ml-auto mr-auto">
                        {success ? (
                            <FormattedMessage id="forgot.gotosignin" defaultMessage="Go to sign in" />
                        ) : (
                            <FormattedMessage id="cancel" defaultMessage="Cancel" />
                        )}
                    </Link>

                    {disabledEmailBtn ? (
                        <div className="btn btn-black-cust ml-auto mr-auto " onClick={handleLogin}>
                            {loading ? (
                                <FormattedMessage id="forgot.loading" defaultMessage="Loading..." />
                            ) : (
                                <FormattedMessage id="signup" defaultMessage="Sign up" />
                            )}
                        </div>
                    ) : null}
                </div>
                <div className="validation-messages">
                    {error && <div className="error ">{error}</div>}
                    {success && <div className="success ">{success}</div>}
                </div>
            </div>
        </div>
    );
}

export default ForgotForm;
