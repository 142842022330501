import React, { useEffect, useState } from 'react';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
// import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table/dist';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { formatDKK, formatNumberAsDanish, roundToDecimals } from '../../../utils/common';
import { FormattedMessage } from 'react-intl';

function createData(
    id,
    agreementId,
    metal,
    type,
    principalAmountInG,
    currentAmountInG,
    startPrice,
    startDate,
    costsPerMonth,
    startBindingDate,
    endBindingDate,
    interestRate,
    initialMarketValue,
    gainOrLoss
) {
    return {
        id,
        agreementId,
        metal,
        type,
        principalAmountInG,
        currentAmountInG,
        startPrice,
        costsPerMonth,
        startDate,
        startBindingDate,
        endBindingDate,
        interestRate,
        initialMarketValue,
        gainOrLoss,
    };
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 430,
    },
});

const getMarketValue = (amountInGrams, currentExchangePrice) => {
    return roundToDecimals(amountInGrams, 2) * roundToDecimals(currentExchangePrice, 2);
};

const getDevelopmentSinceStart = (
    intialAmountInGrams,
    intialExchangePricePerGram,
    currentAmountInGrams,
    currentExchangePricePerGram,
    format = true
) => {
    const initialMarketValue = intialAmountInGrams * intialExchangePricePerGram;
    const currentMarketValue = roundToDecimals(currentAmountInGrams,2) * roundToDecimals(currentExchangePricePerGram, 2);
    const delta = (currentMarketValue - initialMarketValue) / initialMarketValue;
    if (Number.isNaN(delta)) return <span>0.00%</span>;
    if (format) {
        if (delta > 0) {
            return <span className="text-success">+ {formatNumberAsDanish(delta * 100)}%</span>;
        } else if(delta === 0) {
            return <span>{formatNumberAsDanish(delta * 100)}%</span>;
        }
        return <span className="text-danger">{formatNumberAsDanish(delta * 100)}%</span>;
    }
    return delta;
};

function ActiveContractsTable(props) {
    let [row_arr, setArr] = useState([]);

    const columns = [
        { id: 'agreementId', label: 'Aftalenummer', minWidth: 100, align: 'center' },
        { id: 'metal', label: 'Metal', minWidth: 100, align: 'center' },
        { id: 'type', label: 'Type', minWidth: 100, align: 'center' },
        { id: 'principalAmountInG', label: 'Depot startbeholdning (g)', minWidth: 100, align: 'center' },
        { id: 'currentAmountInG', label: 'Indestående (g)', minWidth: 100, align: 'center' },
        { id: 'startPrice', label: 'Start pris', minWidth: 100, align: 'center' },
        { id: 'startDate', label: 'Dato oprettet', minWidth: 100, align: 'center' },
        { id: 'costsPerMonth', label: 'Omkostninger pr. måned', minWidth: 100, align: 'center' },
        { id: 'startBindingDate', label: 'Bindingsdato start', minWidth: 100, align: 'center' },
        { id: 'endBindingDate', label: 'Bindingsdato slut', minWidth: 100, align: 'center' },
        { id: 'interestRate', label: 'Årlig rente', minWidth: 100, align: 'center' },
        { id: 'initialMarketValue', label: 'Markedsværdi', minWidth: 100, align: 'center' },
        { id: 'gainOrLoss', label: 'Resultat', minWidth: 100, align: 'center' },
    ];
    let rows = [];

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    let [dataUser, setDataUser] = useState({});
    let [exchangePrice, setExchangePrice] = useState(0);
    let [activeContracts, setActiveContracts] = useState([]);

    let response = props.onGetData;

    useEffect(() => {
        setDataUser(response);
        setActiveContracts(response);
        setExchangePrice(response.currentExchangePrice);
        let arr = [];
        arr = response.goldDepots.map((data, index) => {
            const exchangePrice = data.metalType === 'gold' ? response.currentExchangePriceGold : response.currentExchangePriceSilver;
            const digits = data.metalType === 'gold' ? 2 : 0;
            return createData(
                data.id,
                data.agreementId,
                data.metalType,
                data.type === 'locked' ? (
                    <FormattedMessage id="depot.type.locked" defaultMessage="locked" />
                ) : (
                    <FormattedMessage id="depot.type.standard" defaultMessage="standard" />
                ),
                formatNumberAsDanish(data.principalAmountInG, digits),
                formatNumberAsDanish(data.currentAmountInG, digits),
                formatDKK(data.startExchangePrice),
                new Date(data.createdAt).toLocaleDateString(),
                data.isInBinding ? '-' : `${formatNumberAsDanish(data.costsPerMonth, 3)}%`,
                data.startBindingDate ? new Date(data.startBindingDate).toLocaleDateString() : '-',
                data.endBindingDate ? new Date(data.endBindingDate).toLocaleDateString() : '-',
                data.interestRate && data.isInBinding ? `${formatNumberAsDanish(data.interestRate)}%` : '-',
                formatDKK(getMarketValue(data.currentAmountInG || data.principalAmountInG, exchangePrice)),
                getDevelopmentSinceStart(data.principalAmountInG, data.startExchangePrice, data.currentAmountInG, exchangePrice)
            );
        });
        setArr(arr);
    }, []);

    return (
        <>
            <div className={`${row_arr.filter((r) => r.days_until_repurchase < 0).length > 0 ? '' : 'd-none'} mb-3 border bg-danger p-3`}>
                <FormattedMessage id="youHave" defaultMessage="You have" />
                {` ${row_arr.filter((r) => r.days_until_repurchase < 0).length} `}
                <FormattedMessage id="expiredContracts" defaultMessage="contracts that is expired. If you don't renew them, they will be sold." />
            </div>
            <div className="table-ui mb-5">
                <Paper className={classes.root}>
                    <div className="web-table">
                        <TableContainer className={classes.container}>
                            <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    background: '#ECECEC',
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row_arr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        return (
                                            <TableRow
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                                className={
                                                    row.days_until_repurchase < 0 && row.status?.toLowerCase().indexOf('(') < 0 ? 'expired' : ''
                                                }
                                            >
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={lengthActiveContracts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}
                    </div>
                    <div className="mobile-table">
                        <Table>
                            <Thead>
                                <Tr>
                                    {columns.map((column) => (
                                        <Th key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                            {column.label}
                                        </Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {row_arr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <Tr role="checkbox" tabIndex={-1} key={row.code} className={row.days_until_repurchase < 0 ? 'expired' : ''}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <Td key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </Td>
                                                );
                                            })}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                        {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={lengthActiveContracts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}
                    </div>
                </Paper>
            </div>
        </>
    );
}

export default ActiveContractsTable;
