import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './style.css';
import logo from '../../assets/img/logo.png';

function Headers() {
    const checkActive = (match, location) => {
        //some additional logic to verify you are in the home URI
        if (!location) return false;
        const { pathname } = location;
        return pathname === '/';
    };
    return (
        <div className="header w-100">
            <div className="container-fluid mx-auto">
                <Link to="/" className="logo">
                    <img src={logo} alt="" />
                </Link>
            </div>
        </div>
    );
}

export default Headers;
