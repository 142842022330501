import React, { useState } from 'react';
import './style.css';
import logo from '../../assets/img/logo.png';
import Headers from '../../components/header/header';
import ForgotForm from '../../components/forgot_form/forgotForm';

function Forgot() {
    return (
        <main className="min-vh-100 bg-main d-flex flex-column">
            <Headers />
            <div className="d-flex flex-grow-1">
                <div className="container-fluid d-flex justify-content-center align-items-center">
                    <ForgotForm />
                </div>
            </div>
        </main>
    );
}

export default Forgot;
