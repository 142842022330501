export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
};
export const getToken = () => {
    return sessionStorage.getItem('token') || null;
};
export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('token', JSON.stringify(user));
};
export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
};

export const formatDKK = (val, digits = 2) => {
    if (Number.isNaN(Number(val))) return '-';
    return new Intl.NumberFormat('da', {
        style: 'currency',
        currency: 'DKK',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    }).format(val);
};

export const contractIsOnRecurring = (contractData) => {
    return contractData.recurringStatus && contractData.recurringStatus === 'accepted';
};

export const formatNumberAsDanish = (number, digits = 2) => {
    return new Intl.NumberFormat('da', {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
    }).format(number);
};

export const roundToDecimals = (num, places) => {
    return +(Math.round(num + 'e+' + places) + 'e-' + places);
};
