import React, { useEffect, useState } from 'react';
import './style.css';
import Headers from '../../components/header/header';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import MessageNotification from '../../components/message_notification/messageNotification';
import ActiveContractsTable from '../../components/active_contracts_table/activeContractsTable';
import contact_img from '../../assets/img/contact_map.png';
import logaction_icon_img from '../../assets/img/material-location-on.svg';
import clock_icon_img from '../../assets/img/awesome-clock.svg';
import fb_icon_img from '../../assets/img/facebook_icon.svg';
import UpdateProfileModal from '../../components/modals/update_profile_modal';
import MessageNotificationModal from '../../components/modals/message_notification_modal';
import loader from '../../assets/loaderPantsat.svg';
import axios from 'axios';
import config from '../../../utils/config';
import { FormattedMessage } from 'react-intl';
import UpdatePasswordModal from '../../components/modals/update_password';
import { formatDKK, formatNumberAsDanish, roundToDecimals } from '../../../utils/common';

function Contracts() {
    let [dataUser, setDataUser] = useState({});
    let [totalGramsGold, setTotalGramsGold] = useState(0);
    let [totalGramsSilver, setTotalGramsSilver] = useState(0);
    let [totalValueGold, setTotalValueGold] = useState(0);
    let [totalValueSilver, setTotalValueSilver] = useState(0);
    let [developmentSinceStartGold, setDevelopmentSinceStartGold] = useState(0);
    let [developmentSinceStartSilver, setDevelopmentSinceStartSilver] = useState(0);
    const [key, setKey] = useState('contractOverview');
    const [activeTab, setAtciveTab] = useState(false);
    const [list, setUpdateList] = useState([]);
    let [toggleModal, setToggleModal] = useState(false);
    let [toggleModal2, setToggleModal2] = useState(false);
    let [toggleModalChangePassword, setToggleModalChangePassword] = useState(false);
    let [itemIndex, setItemIndex] = useState(0);

    let [pageloader, setPageloader] = useState(true);

    const handleRemoveMessage = (idx) => {
        let arr = [];
        list.forEach((item, index) => {
            if (idx != item.id) {
                arr.push(item);
            }
        });
        setUpdateList(arr);
        let idxString = idx.toString();

        axios
            .post(`${config.baseUrl}/customerportal/toggleMessageVisibility`, {
                messageId: idxString,
            })
            .catch((error) => {
                console.log('error', error);
                // setError(error.response.data.message)
            });
    };
    let toggleSwitch = (k) => {
        if (k === 'contractOverview') {
            setAtciveTab(false);
        } else if (k === 'contactUs') {
            setAtciveTab(true);
        }
    };

    const formatDevelopmentSinceStart = (delta) => {
        if (delta === 0) {
            return <span style={{ font: 'inherit' }}>{formatNumberAsDanish(delta * 100)}%</span>;
        } else if (delta > 0) {
            return (
                <span className="text-success" style={{ font: 'inherit' }}>
                    + {formatNumberAsDanish(delta * 100)}%
                </span>
            );
        } else
            return (
                <span className="text-danger" style={{ font: 'inherit' }}>
                    {formatNumberAsDanish(delta * 100)}%
                </span>
            );
    };

    let changeDataUser = (data) => {
        const initialMarketValueGold = data.goldDepots
            .filter((gd) => gd.metalType === 'gold')
            .reduce((acc, gd) => acc + gd.principalAmountInG * gd.startExchangePrice, 0);
        const initialMarketValueSilver = data.goldDepots
            .filter((gd) => gd.metalType === 'silver')
            .reduce((acc, gd) => acc + gd.principalAmountInG * gd.startExchangePrice, 0);
        const totalGramsGold = data.goldDepots.filter((gd) => gd.metalType === 'gold').reduce((acc, gd) => acc + gd.currentAmountInG, 0);
        const totalGramsSilver = data.goldDepots.filter((gd) => gd.metalType === 'silver').reduce((acc, gd) => acc + gd.currentAmountInG, 0);
        const currentMarketValueGold = roundToDecimals(totalGramsGold, 2) * roundToDecimals(data.currentExchangePriceGold, 2);
        const currentMarketValueSilver = roundToDecimals(totalGramsSilver, 2) * roundToDecimals(data.currentExchangePriceSilver, 2);
        const developmentSinceStartGold = currentMarketValueGold ? (currentMarketValueGold - initialMarketValueGold) / initialMarketValueGold : 0;
        const developmentSinceStartSilver = currentMarketValueSilver
            ? (currentMarketValueSilver - initialMarketValueSilver) / initialMarketValueSilver
            : 0;
        setDataUser(data);
        setUpdateList(data?.messages?.filter((m) => m.show));
        setTotalGramsGold(totalGramsGold);
        setTotalGramsSilver(totalGramsSilver);
        setTotalValueGold(currentMarketValueGold);
        setTotalValueSilver(currentMarketValueSilver);
        setDevelopmentSinceStartGold(developmentSinceStartGold);
        setDevelopmentSinceStartSilver(developmentSinceStartSilver);
        setPageloader(false);
    };

    const logOut = () => {
        const url = `${config.baseUrl}/customerportal/gold/logout`;
        axios
            .post(url)
            .then((response) => {
                window.location.href = '/';
            })
            .catch((error) => {
                console.log('error', error);
                if (error.response.status === 403) {
                    alert('Noget gik galt. Prøv at logge ind igen.');
                    window.location.href = '/';
                } else {
                    alert(`Noget gik galt. (${error.response.data?.message}) Prøv venligst igen.`);
                }
                // setError(error.response.data.message)
            })
            .then(() => {
                changeDataUser({ messages: [] });
            });
    };

    useEffect(() => {
        axios.defaults.withCredentials = true;
        const url = `${config.baseUrl}/customerportal/gold`;
        axios
            .get(url)
            .then((response) => {
                changeDataUser(response.data);
            })
            .catch((error) => {
                console.log('error', error);
                if (error.response.status === 403) {
                    alert('Noget gik galt. Prøv at logge ind igen.');
                    window.location.href = '/';
                } else {
                    alert(`Noget gik galt. (${error.response.data?.message}) Prøv venligst igen.`);
                    window.location.href = '/';
                }
                // setError(error.response.data.message)
            });
    }, []);

    const handelsSpreadTable = {
        headers: ['Handel', 'Køb - spread', 'Salg - spread'],
        rows: [
            ['< 500 gram', '4,0%', '4,0 %'],
            ['500-2000 gram', '3,0 %', '3,0 %'],
            ['> 2000 gram', '2,0 %', '2,0 %'],
        ],
    };

    const depotOmkostningerTable = {
        headers: ['Depot', 'Omkostninger pr. måned'],
        rows: [
            ['< 500 gram', '0,10 %'],
            ['500-2000 gram', '0,075 %'],
            ['> 2000 gram', '0,05 %'],
        ],
    };

    return (
        <main className="bg-main d-flex flex-column">
            {pageloader ? (
                <div className="loader">
                    <img src={loader} alt="loader" />
                </div>
            ) : (
                <div className="d-flex flex-grow-1 flex-column">
                    <Headers />
                    <div className="d-flex flex-grow-1">
                        <div id="logout">
                            <button className={`btn btn-white`} onClick={logOut}>
                                <FormattedMessage id="logOut" defaultMessage="Log out" />
                            </button>
                        </div>
                        <div className="w-100 d-flex justify-content-center align-items-center">
                            <div className="bg-white w-100 mx-2 mx-lg-5 border-radius8 overflow-hidden contract-contact-tabs mb-3">
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => {
                                        setKey(k);
                                        toggleSwitch(k);
                                    }}
                                    className={'flex-column flex-md-row'}
                                >
                                    <Tab eventKey="contractOverview" title="Depotoverblik">
                                        <div className="tab-container px-lg-5">
                                            {list.length > 0 &&
                                                list.map((item, idx) => {
                                                    return (
                                                        <MessageNotification
                                                            key={idx}
                                                            persistent={item.persistent}
                                                            message={item.message}
                                                            url={item.url}
                                                            linkName={item.linkName}
                                                            onPressed={() => {
                                                                setToggleModal2(true);
                                                                setItemIndex(idx);
                                                            }}
                                                            onPress={handleRemoveMessage}
                                                            id={item.id}
                                                        />
                                                    );
                                                })}

                                            <div className="totals">
                                                <div className="total">
                                                    <div className="title">
                                                        <FormattedMessage id="contracts.totalgrams.title" defaultMessage="Total grams in depot" />
                                                    </div>
                                                    <div className="price">Gold: {formatNumberAsDanish(totalGramsGold)} g</div>
                                                    <div className="price">Silver: {formatNumberAsDanish(totalGramsSilver, 0)} g</div>
                                                </div>

                                                <div className="total">
                                                    <div className="title">
                                                        <FormattedMessage id="contracts.totalvalue.title" defaultMessage="Total value in DKK" />
                                                    </div>
                                                    <div className="price">Gold: {formatDKK(totalValueGold)}</div>
                                                    <div className="price">Silver: {formatDKK(totalValueSilver, 0)}</div>
                                                </div>

                                                <div className="total">
                                                    <div className="title">
                                                        <FormattedMessage id="contracts.totalresult.title" defaultMessage="Total result" />
                                                    </div>
                                                    <div className="price">Gold: {formatDevelopmentSinceStart(developmentSinceStartGold)}</div>
                                                    <div className="price">Silver: {formatDevelopmentSinceStart(developmentSinceStartSilver)}</div>
                                                </div>

                                                <div className="total">
                                                    <div className="title">
                                                        <FormattedMessage id="contracts.goldprice.title" defaultMessage="Current metal price" />
                                                    </div>
                                                    <div className="price">Gold: {formatDKK(dataUser.currentExchangePriceGold)}/g</div>
                                                    <div className="price">Silver: {formatDKK(dataUser.currentExchangePriceSilver)}/g</div>
                                                    <div className="small mb-3 text-muted fs-1">
                                                        <FormattedMessage
                                                            id="contracts.goldprice.lastupdated"
                                                            defaultMessage="*Last updated {date}"
                                                            values={{ date: new Date(dataUser.lastUpdatedExchangePrice).toLocaleString() }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="title-contract">
                                                <FormattedMessage id="contracts.contactInformation" defaultMessage="Contact Information" />
                                            </div>
                                            <div className="account-information d-flex align-items-center mg-b-20 flex-wrap">
                                                <div className="center mr-auto d-flex align-items-center flex-grow-1 my-2">
                                                    <div className="d-flex info align-items-center">
                                                        <span className="title">
                                                            <FormattedMessage id="customerId" defaultMessage="customer id" />:
                                                        </span>
                                                        <span className="item">{dataUser.customerId}</span>
                                                    </div>
                                                    <div className="d-flex info ml-4 align-items-center">
                                                        <span className="title">
                                                            <FormattedMessage id="phone" defaultMessage="Telefonnummer" />:
                                                        </span>
                                                        <span className="item">{dataUser.phone}</span>
                                                    </div>
                                                    <div className="d-flex info ml-4 align-items-center">
                                                        <span className="title">Email:</span>
                                                        <span className="item">{dataUser.email}</span>
                                                    </div>
                                                </div>
                                                <div className="mx-auto my-auto d-flex">
                                                    <div className="btn btn-black-cust py-2" onClick={() => setToggleModal(!toggleModal)}>
                                                        <FormattedMessage id="update" defaultMessage="Update" />
                                                    </div>
                                                    <div
                                                        className="btn btn-black-cust py-2 ml-2"
                                                        onClick={() => setToggleModalChangePassword(!toggleModalChangePassword)}
                                                    >
                                                        <FormattedMessage id="changePassword" defaultMessage="Change password" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="title-contract">
                                                <FormattedMessage id="depots.activeDepots" defaultMessage="Active depots" />
                                            </div>
                                            <ActiveContractsTable onGetData={dataUser} />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="terms" title="Vilkår">
                                        <div className="termsContainer tab-container position-relative">
                                            <div className="z-index-1 p-1 p-lg-5">
                                                <h2 className="mb-3">Depot- og handelsvilkår</h2>
                                                <div dangerouslySetInnerHTML={{ __html: dataUser.termsHTML }}></div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="contactUs" title="Kontakt">
                                        <div className="contactUs two-grid tab-container position-relative">
                                            <div className="z-index-1">
                                                <div className="mg-b-60 mg-t-26">
                                                    <h2 className="color-gold mg-b-20">
                                                        <FormattedMessage id="shops" defaultMessage="Departments" />
                                                    </h2>
                                                    <p className="main-color ft-sz-20">
                                                        <FormattedMessage
                                                            id="shopsInto"
                                                            defaultMessage="We are happy to welcome you into our stores on the following adresses"
                                                        />
                                                    </p>
                                                </div>
                                                <div className="max-475">
                                                    <a
                                                        href="https://goo.gl/maps/EcuyjHoYJeYWr2WY6"
                                                        className="color-gold mg-b-40 d-flex align-items-start"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img src={logaction_icon_img} className="mr-4" alt="location" />
                                                        <span className="ft-sz-20 color-gold font-weight-bold bold-family">
                                                            København - H.C. Andersens Boulevard 38, st. tv., 1553 København V
                                                        </span>
                                                    </a>
                                                    <div className="d-flex font-weight-bold bold-family main-color mg-b-40 align-items-start">
                                                        <img src={clock_icon_img} className="mr-4" alt="location" />
                                                        <span>
                                                            <FormattedMessage
                                                                id="openingHours"
                                                                defaultMessage="Opening hours: Monday-Thursdag 10:00-18:00* Friday 10:00-17:00*"
                                                            />
                                                        </span>
                                                    </div>
                                                    <a
                                                        href="https://goo.gl/maps/u9YB8tvvNBYTDEbW7"
                                                        className="color-gold mg-b-40 d-flex align-items-start"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img src={logaction_icon_img} className="mr-4" alt="location" />
                                                        <span className="ft-sz-20 color-gold font-weight-bold bold-family">
                                                            Østergade 33, st. th., 8000 Aarhus C
                                                        </span>
                                                    </a>
                                                    <div to="#" className="d-flex font-weight-bold bold-family main-color mg-b-40 align-items-start">
                                                        <img src={clock_icon_img} className="mr-4" alt="location" />
                                                        <span>
                                                            <FormattedMessage
                                                                id="openingHoursAArhus"
                                                                defaultMessage="Opening hours: Monday-Thursdag 10:00-18:00* Friday 10:00-15:30*"
                                                            />
                                                        </span>
                                                    </div>
                                                    <a
                                                        href="tel:71 74 30 65"
                                                        className="d-flex font-weight-bold bold-family main-color mg-b-40 align-items-start"
                                                    >
                                                        <span className="ft-sz-20 main-color font-weight-bold bold-family mr-4">
                                                            <FormattedMessage id="phone" defaultMessage="Phone number" />:{' '}
                                                        </span>
                                                        <span className="ft-sz-20 color-gold font-weight-bold bold-family">71 74 30 65</span>
                                                    </a>
                                                    <a
                                                        href="mailto:info@nordiskguld.dk"
                                                        className="d-flex font-weight-bold bold-family main-color mg-b-40 align-items-start"
                                                    >
                                                        <span className="ft-sz-20 main-color font-weight-bold bold-family mr-4">E-mail: </span>
                                                        <span className="ft-sz-20 color-gold font-weight-bold bold-family">info@nordiskguld.dk</span>
                                                    </a>
                                                    <a
                                                        href="https://www.facebook.com/pantsat.dk/"
                                                        className="d-block mg-b-40"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img src={fb_icon_img} alt="Facebook" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="img img-contact">
                                                <img src={contact_img} alt="" />
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    {/*modals*/}

                    <UpdateProfileModal
                        title={'Kontakt information '}
                        onPress={setToggleModal}
                        show={toggleModal}
                        currentPhone={dataUser.phone}
                        currentEmail={dataUser.email}
                    />
                    {/*<BookMeetingModal title={"Book a meeting"} onPress ={setToggleModal7} show={toggleModal7}/>*/}
                    <MessageNotificationModal title={''} message={list[itemIndex]?.message} onPress={setToggleModal2} show={toggleModal2} />
                    <UpdatePasswordModal
                        title={'Skift kodeord'}
                        onPress={setToggleModalChangePassword}
                        show={toggleModalChangePassword}
                        email={dataUser.email}
                    />
                    {/*modals end*/}
                </div>
            )}
        </main>
    );
}

export default Contracts;
