import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import './style.css';
import logo from '../../assets/img/logo.png';
import axios from 'axios';
import validator from 'validator';
import config from '../../../utils/config';
import { FormattedMessage } from 'react-intl';
// import {setUserSession} from "../../../utils/common";

function LoginForm(props) {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const validateEmail = (e) => {
        var email = e.target.value;
        if (!validator.isEmail(email)) {
            setError('Enter valid Email!');
        } else {
            setError('');
        }
    };

    const handleLogin = () => {
        if (!password || !email) {
            return setError('Udfyld både kodeord og email');
        }
        // props.history.push('/contracts')
        setError(null);
        setLoading(true);

        axios
            .post(
                `${config.baseUrl}/customerportal/gold/login`,
                {
                    email: email,
                    password: password,
                },
                { withCredentials: true }
            )
            .then((response) => {
                setLoading(false);
                let token = response.data.token;
                document.cookie = 'token' + '=' + (token || '') + '; path=/';
                history.push('/contracts');
            })
            .catch((error) => {
                setLoading(false);
                setError(error.response.data.message);
            });
    };
    const keyPress = (e) => {
        if (e.which == 13) {
            handleLogin();
        }
    };
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const message = urlParams.get('message');
        setMessage(message);

        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);
    return (
        <div className="form brd-rds-4 bg-white padding-20 mx-wdt-740px w-100 text-center">
            <div className="mx-wdt-420px m-auto">
                <h3 className="font-weight-bold mg-b-32">
                    <FormattedMessage id="welcome.customerportal" defaultMessage="Welcome!" />
                </h3>
                <div className="text-left mb-4">
                    <FormattedMessage
                        id="welcome.intro"
                        defaultMessage="Welcome to your customer portal. Here you are able to get an overview over your gold depot"
                    />
                </div>
                {message && <h6 className="text-success">{message}</h6>}
                <div className="input-group">
                    <label htmlFor="Email">Email</label>
                    <input
                        type="email"
                        id="Email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            validateEmail(e);
                        }}
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="Pass">
                        <FormattedMessage id="password" defaultMessage="Password" />
                    </label>
                    <input type="password" id="Pass" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="d-flex">
                    <Link to="/forgot" className="forgot-link d-block text-left mg-b-20">
                        <FormattedMessage id="forgot.password" defaultMessage="Forgot password" />
                    </Link>
                    {/* <Link to="/signup" className="forgot-link d-block text-right mg-b-20 ml-auto">
                        <FormattedMessage id="signup" defaultMessage="Sign up" />
                    </Link> */}
                </div>
                <div className="small p-3 mb-3 border bg-warning">
                    <FormattedMessage
                        id="welcome.howtosignup"
                        defaultMessage="If you are not yet a customer of our gold depot solution or have not gotten your login, please contact us at 71743065 or info@nordiskguld.dk"
                    />
                </div>
                <div className="btn btn-black-cust" onClick={handleLogin}>
                    {loading ? 'loading ...' : 'Login'}
                </div>
                <div className="validation-messages">{error && <div className="error ">{error}</div>}</div>
            </div>
        </div>
    );
}

export default LoginForm;
